import { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import localeContext from '../localeContext';

export default ({ location }) => {
  const { locale } = useContext(localeContext) || {};
  console.log('Redirecting from pathname: ', location.pathname);
  useEffect(() => {
    navigate((locale === 'en' && '/') || `/${locale}`);
  }, []);
  return null;
};
